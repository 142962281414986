export const NATIVE_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const DEFAULT_FIXED_DECIMAL_POINT = 5;
export const LP_FEE_FRACTION = 0.1;
export const DEPOSIT_TAG = 'HYPHEN_UI';
export const BASE_DIVISOR = 100000000;
// export const MANUAL_EXIT_RETRIES = 12;

const CONSTANTS = {
  NATIVE_ADDRESS,
  DEFAULT_FIXED_DECIMAL_POINT,
  DEPOSIT_TAG,
  BASE_DIVISOR,
};

export const USDT_ADDRESS = '0x64ef393b6846114bad71e2cb2ccc3e10736b5716'


export default CONSTANTS;
